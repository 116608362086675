import { Fab, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment } from "react";
import { IAtlasDownloads } from "../AtlasModels";
import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles(() => ({
  fab: {
    backgroundColor: "#FFF",
    margin: "7px",
    lineHeight: "16px",
    textAlign: "left",
    fontSize: "13px",
    color: "#112345",
    position: "relative"
  },
  fabIcon: {
    height: "20px",
    paddingRight: "10px",
    display: "none",
    padding: "10px"
  },
  header: {
    fontSize: "14px",
    padding: "0px",
    marginBottom: "0px",
    fontWeight: 700
  },
  desc: {
    fontSize: "16px",
    color: "rgb(107, 107, 118);",
    margin: "0px",
    padding: "10px 0px",
  },
  cols: {
    fontSize: "14px",
    color: "rgb(107, 107, 118)",
    paddingBottom: 20,
    position: "relative"
  },
  colkey: {
    textTransform: "capitalize",
    paddingRight: 5
  },
  colval: {
    paddingRight: 5
  },
  colsLbl: {
    position: "absolute",
    left: -25,
    top: 30,
    transform: "rotate(90deg)"
  },
  downloads: {
    textAlign: "left",
    marginBottom: "40px"
  }
}));

const downloadFile = (file: string) => {
  alert(file);

};
const AtlasDownloads: React.FC<any> = ({
  downloads,
  download,
  theme
}) => {
  const classes = useStyles(theme);

  if (downloads && downloads.length > 0) {
    const downloadData: IAtlasDownloads = downloads[
      download
    ] as IAtlasDownloads;

    const sections = downloadData.sections.map((section, index: number) => {
      return (
        <section key={index}>
          <h3 className={classes.header}>{section.headline}</h3>
          <div className={classes.desc}>
            {section.desc.split("\n").map(
              (desc: string, descIndex: number) =>
                (<Fragment key={descIndex}>{desc}</Fragment>)
            )}
          </div>
          <div className={classes.cols}>
            Data Elements
            {section.columns.map((column: [string, string], colIndex: number) => (
              <div key={colIndex}>
                <span className={classes.colkey}>{column[0]}:</span>
                <span className={classes.colval}>{column[1]}</span>
              </div>
            )
            )}
          </div>
          <div className={classes.downloads}>
            {
              section.files.map((file, fileIndex: number) => {
                return (
                  <a key={fileIndex} href={file.url} download={file.name}>
                    <Fab
                      variant="extended"
                      aria-label="Ensembl"
                      className={classes.fab}
                      style={{ width: 250 }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: 21,
                          width: 150
                        }}
                      >
                        {file.desc}
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: 180,
                          border: "0px solid #BBB",
                          borderLeftWidth: 1,
                          marginLeft: 10,
                          paddingLeft: 10
                        }}
                      >{file.size}<br />{file.type}
                      </div>
                    </Fab>
                  </a>
                );
              })
            }
          </div>
        </section>
      );
    });

    return (
      <AtlasPanel title="Downloads">
        {sections}
      </AtlasPanel>
    );
  } else {
    return <Fragment />;
  }
};
export default AtlasDownloads;

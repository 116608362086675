import {
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import React, { Fragment } from "react";
import { IAtlasGene, IGenerifEntity } from "../AtlasModels";
import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    backgroundColor: "#FFF",
    margin: "5px",
    color: theme.palette.primary.dark,
    fontSize: "14px"
  },
  fabIcon: {
    height: "20px",
    paddingRight: "10px",
    display: "none"
  }
}));

const AtlasGeneInfo: React.FC<any> = ({ genes, gene, theme }) => {
  const classes = useStyles(theme);

  // Component State
  const openPage = (url: string) => {
    window.open(url, "_blank");
  };
  if (genes && genes.length > 0) {

    const geneData = genes[gene] as IAtlasGene;
    geneData.alias = geneData.alias || [];
    if (!Array.isArray(geneData.alias)) {
      geneData.alias = [String(geneData.alias)];
    }
    const ensembl = (geneData.ensembl) ? (geneData.ensembl.gene || null) : null;
    return (
      <AtlasPanel title={geneData.symbol}>
        <Grid item xs={12} md={8} >
          <Typography style={{ textTransform: "capitalize" }}>
            {geneData.name}
          </Typography>
          {geneData.type_of_gene}
          {(geneData.alias.length) ? " / Aliases: " + geneData.alias.join(", ") : ""}
          <p style={{ fontSize: "16px", color: "#6b6b76" }}>
            {geneData.summary}
          </p>
        </Grid>
        <Grid item xs={12} md={(geneData.summary) ? 4 : 12}>
          <div style={{ textAlign: (geneData.summary) ? "center" : "left", paddingTop: (geneData.summary) ? "20px" : "0px" }}>
            <Fab
              style={{ display: ensembl ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="Ensembl"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  `http://uswest.ensembl.org/Homo_sapiens/Gene/Summary?g=${ensembl}`
                )
              }
            >
              Ensembl
            </Fab>
            <Fab
              style={{ display: geneData.entrezgene ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="Ensembl"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  `https://www.ncbi.nlm.nih.gov/gene/?term=${geneData.entrezgene}`
                )
              }
            >
              Entrez
            </Fab>
            <Fab
              style={{ display: geneData.HGNC ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="HGNC"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  `https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/HGNC:${geneData.HGNC}`
                )
              }
            >
              HGNC
            </Fab>
            <Fab
              style={{ display: geneData.HPRD ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="Ensembl"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  `http://hprd.org/resultsQuery?multiplefound=&prot_name=&external=Ref_seq&accession_id=&hprd=${geneData.HPRD}&gene_symbol=&chromo_locus=&function=&ptm_type=&localization=&domain=&motif=&expression=&prot_start=&prot_end=&limit=0&mole_start=&mole_end=&disease=&query_submit=Search`
                )
              }
            >
              HPRD
            </Fab>
            <Fab
              style={{ display: geneData.MIM ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="OMIM"
              className={classes.fab}
              onClick={(e) =>
                openPage(`https://www.omim.org/entry/${geneData.MIM}`)
              }
            >
              OMIM
            </Fab>
            <Fab
              style={{ display: geneData.pharmgkb ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="Pharmgkb"
              className={classes.fab}
              onClick={(e) =>
                openPage("https://www.pharmgkb.org/gene/" + geneData.pharmgkb)
              }
            >
              Pharmgkb
            </Fab>
            <Fab
              style={{ display: geneData.uniprot ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="Uniprot"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  `https://www.uniprot.org/uniprot/${geneData.uniprot["Swiss-Prot"]}`
                )
              }
            >
              Uniprot
            </Fab>
            <Fab
              style={{ display: geneData.Vega ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="VEGA"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  `http://vega.archive.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=${geneData.Vega}`
                )
              }
            >
              VEGA
            </Fab>
            <Fab
              style={{ display: geneData.wikipedia ? "inline-flex" : "none" }}
              variant="extended"
              aria-label="Wikipedia"
              className={classes.fab}
              onClick={(e) =>
                openPage(
                  "https://en.wikipedia.org/wiki/" + geneData.wikipedia.url_stub
                )
              }
            >
              Wikipedia
            </Fab>
          </div>
        </Grid>
      </AtlasPanel>
    );
  } else {
    return <Fragment />;
  }
};
export default AtlasGeneInfo;

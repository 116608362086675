import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import axios from "axios";
import React, { Fragment } from "react";
import { IAtlasGene, IGenerifEntity } from "../AtlasModels";
import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "120px 0px"
  },
  searchBox: {
    width: "30%",
    margin: "10px 0px"
  },
  link: {
    // width: '30%',
    "marginBottom": "20px",
    "textDecoration": "none",
    "color": theme.palette.text.primary,
    "display": "block",
    "&:visited": {
      textDecoration: "none"
    },
    "&:hover": {
      backgroundColor: "#EEEEEE"
    }
  }
}));

const AtlasPubmed: React.FC<any> = ({ genes, gene, theme }) => {
  const classes = useStyles(theme);
  const handleLoadClick = (id: string) => {
    axios
      .get<any>(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pmc&retmode=json&id=${id}`
      );
    // .then((response) => {  });
  };
  if (genes && genes.length > 0) {
    const data = genes[gene] as IAtlasGene;
    data.generif = data.generif || [];

    const pubmed = data.generif.map((pub: IGenerifEntity, i: number) => {
      return (
        <Button key={i} onClick={() => handleLoadClick(pub.pubmed.toString())}>
          {pub.text}
        </Button>
      );
    });
    return (
      <AtlasPanel
        title={"Gene Reference into Function (" + pubmed.length + " Results)"}
      >
        {pubmed}
      </AtlasPanel>
    );
  } else {
    return <Fragment />;
  }
};
export default AtlasPubmed;

import { Container, Grid } from "@material-ui/core";
import { graphql } from "gatsby";
import * as _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAtlasDatumRequest } from "src/atlas/AtlasModels";
import { IAppState } from "src/redux/store";
import { loadData } from "../atlas/AtlasActionFactory";
import AtlasDownloads from "../atlas/blades/AtlasDownloads";
import AtlasGeneInfo from "../atlas/blades/AtlasGeneInfo";
import AtlasGo from "../atlas/blades/AtlasGo";
import AtlasHeatmap from "../atlas/blades/AtlasHeatmap";
import AtlasPathways from "../atlas/blades/AtlasPathways";
import AtlasPercentBar from "../atlas/blades/AtlasPercentBar";
import AtlasPubmed from "../atlas/blades/AtlasPubmed";
// import AtlasScatter2d from "../atlas/blades/AtlasScatter2d";
import AtlasSearch from "../atlas/blades/AtlasSearch";
import AtlasStackedBar from "../atlas/blades/AtlasStackedBar";
import AtlasTable from "../atlas/blades/AtlasTable";
import AtlasLayout from "../atlas/layout/AtlasLayout";
import FooterBBI from "../atlas/layout/FooterBBI";

interface IProps {
  title: string;
  models: Array<IAtlasDatumRequest>;
  components: [
    {
      component: string;
      gene?: string;
      labels?: string;
      diffExp?: string;
      points?: string;
      table?: string;
      attributes?: string;
      downloads?: string;
    }
  ];
}

export const AtlasPageTemplate: React.FC<any> = ({
  components,
  models,
  title
}) => {
  // Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    // Add validation logic to safeguard user entry
    const indexes = _.groupBy(models, (v) => v.type);
    models.forEach((model: IAtlasDatumRequest) => {
      model.index = indexes[model.type].indexOf(model);
    });
    components.forEach((component: any) => {
      ["gene", "points", "attributes", "labels", "table", "downloads", "jsons", "csv"].forEach(
        (key: string) => {
          if (component[key]) {
            const val = indexes[key].find(
              (index: any) => index.name === component[key]
            );
            if (val) {
              component[key] = val.index;
            }
          }
        }
      );
    });
    dispatch(loadData(models));
  }, []);

  // Component State
  const genes = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.genes : [];
  });
  const points = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.points : [];
  });
  const attributes = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.attributes : [];
  });
  const labels = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.labels : [];
  });
  const csvs = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.csvs : [];
  });
  const tables = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.tables : [];
  });
  const downloads = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.downloads : [];
  });
  const jsons = useSelector((state: IAppState) => {
    return state.atlasState ? state.atlasState.jsons : [];
  });

  // Sub-Components
  const elComponents: any = [];
  const actions: any = [];

  for (const [index, value] of components.entries()) {
    switch (value.component) {
      case "atlas-downloads":
        elComponents.push(
          <AtlasDownloads
            title={value.title}
            downloads={downloads}
            download={value.downloads}
            key={index}
          />
        );
        break;
      case "atlas-search":
        elComponents.push(<AtlasSearch jsons={jsons} json={value.json} key={index} />);
        break;
      case "atlas-gene-info":
        elComponents.push(
          <AtlasGeneInfo genes={genes} gene={value.gene} key={index} />
        );
        break;
      case "atlas-pubmed":
        elComponents.push(
          <AtlasPubmed genes={genes} gene={value.gene} key={index} />
        );
        break;
      case "atlas-pathways":
        elComponents.push(
          <AtlasPathways genes={genes} gene={value.gene} key={index} />
        );
        break;
      case "atlas-go":
        elComponents.push(
          <AtlasGo
            genes={genes}
            gene={value.gene}
            key={index}
            title={value.title}
          />
        );
        break;
      case "atlas-percent-bar":
        elComponents.push(
          <AtlasPercentBar
            key={index}
            csvs={csvs}
            csv={value.csv}
            title={value.title}
          />
        );
        break;
      case "atlas-stacked-bar":
        elComponents.push(
          <AtlasStackedBar
            csvs={csvs}
            csv={value.csv}
            columns={value.columns}
            xs={value.xs}
            title={value.title}
            key={index}
          />
        );
        break;
      // case "atlas-scatter-2d":
      //   elComponents.push(
      //     <AtlasScatter2d
      //       key={index}
      //       points={points}
      //       point={value.points}
      //       attributes={attributes}
      //       attribute={value.attributes}
      //       title={value.title}
      //       labels={labels}
      //       label={value.labels}
      //     />
      //   );
      //   break;
      case "atlas-heatmap":
        elComponents.push(
          <AtlasHeatmap
            key={index}
            points={points}
            point={value.points}
            attributes={attributes}
            attribute={value.attributes}
            title={value.title}
            labels={labels}
            label={value.labels}
          />
        );
        break;
      case "atlas-table":
        elComponents.push(
          <AtlasTable
            csvs={csvs}
            csv={value.csv}
            title={value.title}
            key={index}
          />
        );
        break;
      case "atlas-enhancers":
        break;
      case "atlas-download":
        break;
      case "atlas-romance":
        break;
    }
  }
  return (
    <Container fixed style={{ paddingBottom: "60px" }}>
      <Grid container spacing={4}>
        {elComponents}
      </Grid>
    </Container>
  );
};

const AtlasPage = (props: any) => {
  // const path: Array<string> = props.location.href.split("/");

  // const parts = props.location.href.split("/");
  // const path = parts[parts.length -
  const { frontmatter, html } = props.data.page;
  frontmatter.models.forEach((model: IAtlasDatumRequest) => {
    // model.url = model.url.replace(/{[0-9]+}/gi, (m: string) => {
    //   return path[path.length - parseInt(m.replace("{", "").replace("}", ""), 10) - 1];
    // });
  });
  return (
    <AtlasLayout config={props.data.config.frontmatter}>
      <AtlasPageTemplate
        title={frontmatter.title}
        components={frontmatter.components}
        models={frontmatter.models}
      />
      <FooterBBI />
    </AtlasLayout>
  );
};

export default AtlasPage;

export const pageQuery = graphql`
  query AtlasPage($id: String, $cid: String) {
    config: markdownRemark(id: { eq: $cid }) {
      frontmatter {
        title
        navigation {
          link
          label
        }
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        models {
          name
          type
          url
        }
        components {
          component
          gene
          points
          attributes
          downloads
          labels
          table
          title
          json
          csv
          columns
        }
      }
    }
  }
`;

import { Grid, makeStyles } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React, { Fragment } from "react";

const AtlasTable: React.FC<any> = ({ csvs, csv, theme }) => {
  if (csvs.length > 0) {
    const data = csvs[csv]
      .map((v: Array<string>) => v.reduce((p, c, i) => {
        p[i] = c; return p;
      }, {}))
      .sort((a, b) => parseFloat(b[9]) - parseFloat(a[9]));
    const cols: Column<{}>[] = [
      "ensembl", "organ", "cell type 1", "cell type 2", "cell type 1 expr", "cell type 2 expr", "fold change", "qval"
    ].map((v, i) => ({
      field: i.toString(),
      title: v.replace(/_/gi, " "),
      cellStyle: {
        fontSize: "14px",
        padding: "3px 13px",
        color: "#6b6b76",
        textTransform: "capitalize"
      }
    })) as Column<{}>[];

    cols[0].hidden = true;

    const pageSizeOptions = [10, 50, 100].filter((v: number) => (v < data.length));
    if (pageSizeOptions.length < 3) { pageSizeOptions.push(data.length); }

    return (
      <Grid item xs={12}>
        <MaterialTable
          style={{ width: "100%" }}
          title="Differential Expression"
          options={{
            showTitle: true,
            grouping: true,
            // filtering: true,
            exportButton: true,
            columnsButton: true,
            pageSize: 10,
            pageSizeOptions,
            exportAllData: true,
            headerStyle: {
              fontSize: "12px",
              fontWeight: "bold",
              padding: "3px 13px",
              whiteSpace: "nowrap",
              textTransform: "capitalize"
            },
            rowStyle: {
              fontSize: "13px"
            }
          }}
          columns={cols}
          data={data}
        />
      </Grid>
    );
  } else {
    return <Fragment />;
  }
};
export default AtlasTable;

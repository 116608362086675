
import {
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  panel: {

    padding: "20px",
    scrollSnapAlign: "start",
    scrollSnapStop: "always"
  },
  settingsIconContainer: {
    position: "absolute",
    right: "12px",
    top: "2px"
  },
  settingsIcon: {
    color: "#112345"
  }
}));

const AtlasPanel: React.FC<any> = ({ children, title, theme, xs, md }) => {
  const classes = useStyles(theme);
  if (!xs) { xs = 12; }
  if (!md) { md = 12; }
  return (
    <Grid item xs={xs} md={md}>
      <Paper className={classes.panel} elevation={3}>
        <Typography style={{ marginBottom: 15 }}>{title}</Typography>
        <Grid container>{children}</Grid>
      </Paper>
    </Grid>
  );
};
export default AtlasPanel;

import {
  Button,
  Fab,
  Fade,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { display, width } from "@material-ui/system";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import axios from "axios";
import React, { Fragment, useState } from "react";
import AsyncSelect from "react-select/async";

const useStyles = makeStyles((theme: Theme) => ({
  logoLarge: {
    marginTop: 120,
    width: 300
  },
  logoSmall: {
    display: "none"
  },
  logoSmallCollapsed: {
    position: "absolute",
    top: 20,
    left: 20,
    height: 40
  },
  searchBox: {
    padding: 20,
    position: "relative"
  },
  searchBoxCollapsed: {
    position: "fixed",
    top: 0,
    left: 0,
    height: 80,
    width: "100%",
    zIndex: 9999,
    padding: 20,
    paddingLeft: 70
  },
  searchInput: {
    display: "inline-block",
    width: "400px",
    height: "40px",
    verticalAlign: "middle"
  },

  fab: {
    marginRight: "10px",
    backgroundColor: "#FFF",
  },
  fabIcon: {
    height: "30px",
    paddingRight: "10px"
  },
  modalButtons: {
    position: "absolute",
    right: 20,
    top: 15
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  modalClose: {
    position: "absolute",
    right: "7px",
    top: "7px"
  },
  modalContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    position: "relative"
  },
  modalUL: {
    "listStyleType": "none",
    "padding": 0,
    "margin": 0,
    "marginTop": 15,
    "columnCount": 3,
    "& li": {
      "color": "rgb(107, 107, 118)",
      "marginRight": 20,
      "transform": "translate(-5px, 0px)",
      "padding": "0px 5px",
      "cursor": "pointer",
      "textTransform": "capitalize",
      "&:hover": {
        background: "#efefef"
      }
    }
  },
  modalDL: {
    "listStyleType": "none",
    "padding": 0,
    "columnCount": 3,
    "& dt": {
      paddingTop: 20
    },
    "& dd": {
      "color": "rgb(107, 107, 118)",
      "marginLeft": 0,
      "cursor": "pointer",
      "textTransform": "capitalize",
      "transform": "translate(-5px, 0px)",
      "padding": "0px 5px",
      "&:hover": {
        background: "#efefef"
      }
    }
  }

}));

let terms: { cells: Array<string>, tissues: Array<string> } = { cells: [], tissues: [] };
const loadEnsemblId = (id: string) => {
  axios
    .get(`http://hutch.cloud/genes/${id}`)
    .then((response) => {
      window.open("/atlas/gene/" + response.data.ensembl.gene, "_self");
    });
};

const loadOptions = (inputValue: string, callback: Function) => {
  if (inputValue === "") {
    callback([]);
    return;
  }
  axios
    .get(`http://hutch.cloud/genes/query?q=${inputValue}&species=human`)
    .then((response) => {
      const iv = inputValue.toLowerCase();
      const tissues = terms.tissues.filter((v) => v.startsWith(iv)).map((v) => ({ label: v, value: "tissue/" + v }));
      const cells = terms.cells.filter((v) => v.startsWith(iv)).map((v) => ({ label: v, value: "cell/" + v }));

      const genes = response.data.hits.map((v: any) => {
        const lbl = new Array<string>();
        if (v.name) { lbl.push(v.name); }
        if (v.symbol) { lbl.push(v.symbol); }
        return {
          label: lbl.join(" - "),
          value: "gene/" + v._id
        };
      });
      callback(
        [
          {
            label: "Tissues",
            options: tissues
          },
          {
            label: "Cells",
            options: cells
          },
          {
            label: "Genes",
            options: genes
          }
        ]
      );
    });
};

// Stateless Functional Component / Returns JSX
const AtlasSearch: React.FC<any> = ({ theme, jsons, json }) => {
  if (jsons.length === 0) { return (<Fragment />); }
  const matches = useMediaQuery("(max-width:960px)");
  terms = jsons[0]; // Dynamic
  terms.cells = terms.cells.map((v: string) => v.toLowerCase().trim());
  terms.tissues = terms.tissues.map((v: string) => v.toLowerCase().trim());
  const classes = useStyles(theme);
  const [collapseNav, setCollapseNav] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [geneOpen, setGeneOpen] = React.useState(false);
  const [cellOpen, setCellOpen] = React.useState(false);
  const [tissueOpen, setTissueOpen] = React.useState(false);
  const handleChange = (e: any) => {
    if (e.value.startsWith("gene")) {
      loadEnsemblId(e.value);
    } else {
      window.open("/atlas/" + e.value, "_self");
    }

    // navigate("/atlas/" + e.value, {replace: true });
  };
  const handleGeneOpen = () => {
    setGeneOpen(true);
  };
  const handleGeneClose = () => {
    setGeneOpen(false);
  };
  const handleCellOpen = () => {
    setCellOpen(true);
  };
  const handleCellClose = () => {
    setCellOpen(false);
  };
  const handleTissueOpen = () => {
    setTissueOpen(true);
  };
  const handleTissueClose = () => {
    setTissueOpen(false);
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.trim();
    setSearchTerm(inputValue);
    return inputValue;
  };
  useScrollPosition(({ currPos }) => {
    setCollapseNav(currPos.y <= -250);
  });

  const formatGroupLabel = (data) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <span>{data.label}</span>
      <span>{data.options.length} Matches</span>
    </div>
  );
  return (
    <Fragment>
      <Modal
        aria-labelledby="Gene Options"
        aria-describedby="Gene Options"
        className={classes.modal}
        open={geneOpen}
        onClose={handleGeneClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={geneOpen}>
          <div className={classes.modalContainer}>
            <IconButton
              className={classes.modalClose}
              aria-label="close"
              onClick={() => setGeneOpen(false)}
            ><Icon>close</Icon>
            </IconButton>
            <Typography>Gene</Typography>
            Multiple identifiers can be used to search for genes.
            <dl className={classes.modalDL}>
              <dt>Gene Symbol</dt><dd onClick={() => handleChange({ value: "1017" })} > CDK2</dd>
              <dt>Gene Alias</dt><dd onClick={() => handleChange({ value: "1017" })} >P33 Protein Kinase</dd>
              <dt>Entrez</dt><dd onClick={() => handleChange({ value: "1017" })} >1017</dd>
              <dt>Ensembl</dt><dd onClick={() => handleChange({ value: "1017" })} >ENSG00000123374</dd>
              <dt>Uniprot</dt><dd onClick={() => handleChange({ value: "1017" })} >P24941</dd>
              <dt>Probe Set</dt><dd onClick={() => handleChange({ value: "1017" })} >204252_at</dd>
            </dl>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="Cell Options"
        aria-describedby="Cell Options"
        className={classes.modal}
        open={cellOpen}
        onClose={handleCellClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={cellOpen}>
          <div className={classes.modalContainer}>
            <IconButton
              className={classes.modalClose}
              aria-label="close"
              onClick={() => setCellOpen(false)}
            ><Icon>close</Icon>
            </IconButton>
            <Typography>Cell Types</Typography>
            <ul className={classes.modalUL}>
              {terms.cells.map((cell: string, i: number) => (
                <li key={i} onClick={() => handleChange({ value: cell })}>{cell}</li>
              ))}
            </ul>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="Tissue Options"
        aria-describedby="Tissue Options"
        className={classes.modal}
        open={tissueOpen}
        onClose={handleTissueClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={tissueOpen}>
          <div className={classes.modalContainer}>
            <IconButton
              className={classes.modalClose}
              aria-label="close"
              onClick={() => setTissueOpen(false)}
            ><Icon>close</Icon>
            </IconButton>
            <Typography>TISSUES</Typography>
            <ul className={classes.modalUL}>
              {terms.tissues.map((tissue: string, i: number) => (
                <li key={i} onClick={() => handleChange({ value: tissue })}>{tissue}</li>
              ))}
            </ul>
          </div>
        </Fade>
      </Modal>
      <Grid item xs={12}>
        <img className={classes.logoLarge} src="/img/atlas-logo.png" />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} className={collapseNav ? classes.searchBoxCollapsed : classes.searchBox}>
          {/* <Typography style={{ paddingBottom: 10 }}>Search</Typography> */}
          <img
            className={collapseNav ?
              classes.logoSmallCollapsed :
              classes.logoSmall} src="/img/atlas-logo-small.png"
          />
          <div className={classes.searchInput}>
            <Button
              variant="contained"
              color="primary"
              style={{
                float: "right",
                marginLeft: 10,
                height: 38
              }}
            >
              Search
            </Button>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              placeholder="Tissue, Cell Type or Gene"
              noOptionsMessage={() => "Enter Tissue, Cell Type or Gene"}
              onChange={handleChange}
              onInputChange={handleInputChange}
              formatGroupLabel={formatGroupLabel}
            />
          </div>
          <div
            className={classes.modalButtons}
            style={{
              visibility: matches ? "hidden" : "visible"
            }}
          >
            <Fab
              variant="extended"
              aria-label="tissue"
              className={classes.fab}
              onClick={handleTissueOpen}
            >
              <img className={classes.fabIcon} src="/img/tissue.png" />
              Tissues
            </Fab>
            <Fab
              variant="extended"
              aria-label="like"
              className={classes.fab}
              onClick={handleCellOpen}
            >
              <img className={classes.fabIcon} src="/img/cell.png" />
              Cells
            </Fab>
            <Fab
              variant="extended"
              aria-label="like"
              className={classes.fab}
              onClick={handleGeneOpen}
            >
              <img className={classes.fabIcon} src="/img/gene.png" />
              Genes
            </Fab>
          </div>
        </Paper>
      </Grid>
    </Fragment>
  );
};
export default AtlasSearch;

export interface IColor {
  hex: string;
  rgb: [number, number, number];
}
export class ColorUtil {

  private static componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  public static DEFAULT_COLOR: IColor = { hex: "#DCDCDC", rgb: [220, 220, 220] };
  public static DISCRETE_COLORS: Array<IColor> = [[31, 120, 180],
  [178, 223, 138],
  [51, 160, 44],
  [251, 154, 153],
  [227, 26, 28],
  [255, 127, 0],
  [106, 61, 154],
  [255, 255, 153],
  [177, 89, 40]].map((v: Array<number>) => ({ rgb: [v[0], v[1], v[2]], hex: ColorUtil.rgbToHex(v[0], v[1], v[2]) }));

  static hexToColor(hex: string): IColor {
    return this.DISCRETE_COLORS.find((v: IColor) => v.hex === hex) || this.DEFAULT_COLOR;
  }
  static rgbToHex(r: number, g: number, b: number) {
    return "#" + ColorUtil.componentToHex(r) + ColorUtil.componentToHex(g) + ColorUtil.componentToHex(b);
  }

  static discreteFromIndex(index: number): IColor {
    return (index < ColorUtil.DISCRETE_COLORS.length) ? ColorUtil.DISCRETE_COLORS[index] : this.DEFAULT_COLOR;
  }

}

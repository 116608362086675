export class UtilText {

  private static _drawingContext: CanvasRenderingContext2D;
  public static get DrawingContext(): any {
    if (this._drawingContext !== undefined) {
      return UtilText._drawingContext;
    } else {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx !== null) {
        ctx.font = "10px \"Lucida Console\", Monaco, monospace";
        ctx.fillStyle = "#000";
        ctx.textBaseline = "hanging";
        ctx.textAlign = "left";
        this._drawingContext = ctx;
      }
      return ctx;
    }
  }

  private static _measureTextCache = {};
  public static measureText(text: string, fontSize = 12): { width: number, height: number } {
    if (UtilText._measureTextCache[text] === undefined) {
      const ctx = UtilText.DrawingContext;
      const width = ctx.measureText(text).width;
      UtilText._measureTextCache[text] = { width: (width / 10) * fontSize, height: fontSize };
    }
    return UtilText._measureTextCache[text];
  }
}

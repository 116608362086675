import {
  Grid
} from "@material-ui/core";
import React, { useState, Fragment, useEffect } from "react";
import {
  FlexibleXYPlot,
  HorizontalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis
} from "react-vis";
import { IAtlasTable } from "../AtlasModels";
import { Pagination } from "./../widgets/Pagination";
import AtlasPanel from "./AtlasPanel";

// const myPalette = ['#FFECB3', '#E85285', '#6a1b9a'];
const myPalette = ["#DCEDC8", "#42B3D5", "#1A237E"];

// Stateless Functional Component / Returns JSX
const AtlasStackedBar: React.FC<any> = ({ columns, csvs, csv, title }) => {

  if (csvs.length <= 0) { return (<Fragment />); }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let data: Array<{ y: string; x: number; color: number }> = [];

  const tableData = csvs[csv];

  const minRow = page * rowsPerPage;
  const maxRow = minRow + rowsPerPage - 1;
  const count = tableData.length;
  data = columns.split(",")
    .map((v: string) => {
      const kv = v.split(":");
      return { y: kv[0].replace(/_/gi, " "), x: parseFloat(tableData[0][parseInt(kv[1], 10)]), color: 0.9 }
    })
    .sort((a, b) => (a.x < b.x ? 1 : a.x > b.x ? -1 : 0))
    .filter((v, i: number) => (i >= minRow && i <= maxRow))
    .reverse();

  const height = Math.max(70, (data.length * 20) + 40);

  return (
    <AtlasPanel title={title} xs={12} md={6}>
      <Grid item xs={12} >
        <FlexibleXYPlot
          yType="ordinal"
          margin={{ left: 200 }}
          color="#112345"
          // colorType="linear"
          // colorDomain={[0, .5, 1]}
          // colorRange={myPalette}
          height={height}
        >
          <VerticalGridLines />
          <HorizontalBarSeries
            data={data}
            onSeriesMouseOver={(e) => { console.log(e); }}
          />
          <XAxis
            tickTotal={10}
            tickSizeInner={0}
            title="% of Cells Expressing"
          />
          <YAxis
            hideLine
            tickSizeOuter={0}
            tickSizeInner={0}
            style={{
              text: {
                stroke: "none",
                fill: "#6b6b76",
                fontSize: 14,
                textTransform: "capitalize"
              }
            }}
          />
        </FlexibleXYPlot>
      </Grid>
      <Grid item xs={12} style={{ padding: "0px 0px 0px 20px" }}>
        <Pagination
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onChangeRowsPerPage={(value: number) => { setPage(0); setRowsPerPage(value); }}
          onChangePage={(value: number) => { setPage(value); }}
        />

      </Grid>
    </AtlasPanel>
  );
};
export default AtlasStackedBar;

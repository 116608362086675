import { Container, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
  footer: {
    background: "#333F48",
    padding: "90px 0px 120px 0px"
  },
  subfooter: {
    background: "#455057",
    height: "90px",
    textAlign: "center"
  },
  logoDvc: {
    marginTop: "20px",
    height: "50px"
  },
  copy: {
    "color": "#FFFFFF",
    "fontSize": "13px",
    "fontStyle": "normal",
    "textDecoration": "none",
    "lineHeight": 1.8,
    "&:visited": {
      color: "#FFF"
    }
  }
}));

// Stateless Functional Component / Returns JSX
const FooterBBI: React.FC<any> = ({ theme }) => {
  const classes = useStyles(theme);
  const [] = useState([]);

  return (
    <footer>
      <div className={classes.footer}>
        <Container fixed style={{ flexGrow: 1 }}>
          <img
            src="/img/logos/logo-bbi-footer.png"
            style={{ width: "233px" }}
          />
          <div style={{ float: "right" }}>
            <address className={classes.copy}>
              357657 | Seattle, WA 98195-8047
              <br />
              Health Sciences Building H-564
            </address>
            <a href="mailto:info@brotmanbaty.org" className={classes.copy}>
              info@brotmanbaty.org
            </a>
            <br />
            <a href="tel:206-543-9660" className={classes.copy}>
              206-543-9660
            </a>
          </div>
        </Container>
      </div>
      <div className={classes.subfooter}>
        <a href="https://viz.fredhutch.org">
          <img src="/img/logos/logo-credits.png" className={classes.logoDvc} />
        </a>
      </div>
    </footer>
  );
};
export default FooterBBI;

import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { COORDINATE_SYSTEM, OrthographicView } from "@deck.gl/core";
import DeckGL from "@deck.gl/react";
import { makeStyles, Theme } from "@material-ui/core";
import React, { MouseEvent, useState } from "react";

import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles((theme: Theme) => ({}));
const controller = new OrthographicView({ controller: true });
const data = {
  labels: new Array<{ field: string; data: any; values: any }>(),
  hulls: [],
  instanceProjections: new Int16Array(),
  instanceAttributes: new Uint8Array(),
  instanceAttributesOffset: 0,
  pointCount: 0
};
const createLayers = (): any => {
  const layers = [];
  if (data.instanceAttributes.length > 0) {

    const hm = new HeatmapLayer({
      id: "scatter-plot",
      coordinateSystem: COORDINATE_SYSTEM.IDENTITY,
      getPosition: (d: any) => d.COORDINATES,
      getWeight: (d: any) => d.WEIGHT
    });
  }
};

const AtlasHeatmap: React.FC<any> = ({
  theme,
  points,
  point,
  attributes,
  attribute,
  labels,
  label,
  title
}) => {
  return (
    <AtlasPanel title={title}>
      <div
        style={{
          width: "100%",
          height: "592px",
          position: "relative"
        }}
      >
        <DeckGL
          width="100%"
          height="592px"
          views={controller}
          layers={createLayers()}
          getCursor={() => "crosshair"}
        />
      </div>
    </AtlasPanel>
  );
};
export default AtlasHeatmap;

import {
  Grid,
  makeStyles,
  Theme
} from "@material-ui/core";
import React, { Fragment } from "react";
import { IAtlasGene } from "../AtlasModels";
import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "120px 0px"
  },
  searchBox: {
    width: "30%",
    margin: "10px 0px"
  },
  link: {
    "textDecoration": "none",
    "color": theme.palette.text.primary,
    "display": "block",
    "&:visited": {
      textDecoration: "none"
    },
    "&:hover": {
      backgroundColor: "#EEEEEE"
    }
  }
}));

const AtlasPathways: React.FC<any> = ({ genes, gene, theme }) => {
  const classes = useStyles(theme);

  if (genes && genes.length > 0) {
    const data = genes[gene] as IAtlasGene;
    let pathways: Array<any> = [];
    if (data.pathway) {
      if (data.pathway.kegg) {
        pathways = pathways.concat(
          data.pathway.kegg.map((v) => Object.assign(v, { src: "kegg" }))
        );
      }
      if (data.pathway.reactome) {
        pathways = pathways.concat(
          data.pathway.reactome.map((v) => Object.assign(v, { src: "reactome" }))
        );
      }
      // if (gene.pathway.smpdb) {
      //   pathways = pathways.concat(
      //     gene.pathway.smpdb.map(v => Object.assign(v, { type: "smpdb" }))
      //   );
      // }
      if (data.pathway.wikipathways) {
        pathways = pathways.concat(
          data.pathway.wikipathways.map((v) =>
            Object.assign(v, { src: "wikipathways" })
          )
        );
      }
    }

    pathways = pathways.map((v: any, i: number) => {
      return (
        <Grid item xs={12} key={i}>
          <a href={"" + v.id} target="blank" className={classes.link}>
            {v.src} : {v.name}
          </a>
        </Grid>
      );
    });

    return (
      <AtlasPanel title={"Pathways (" + pathways.length + " Results)"}>
        {pathways}
      </AtlasPanel>
    );
  } else {
    return <Fragment />;
  }
};
export default AtlasPathways;

import {
  Grid,
  makeStyles,
  Theme
} from "@material-ui/core";
import React, { Fragment } from "react";
import { ColorUtil } from "../utils/UtilColor";
import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "120px 0px"
  }
}));

const AtlasPercentBar: React.FC<any> = ({ csvs, csv, title, theme }) => {
  const classes = useStyles(theme);
  const colors = ColorUtil.DISCRETE_COLORS;
  if (csvs.length <= 0) { return (<Fragment />); }
  const totalCells = csvs[csv].reduce((p, c) => { p += parseInt(c[1], 10); return p; }, 0);
  const data = csvs[csv]
    .map((v) => {
      const count = parseInt(v[1], 10);
      const percent = Math.round((count / totalCells) * 100).toString(10) + "%";
      return { label: v[0], count, percent };
    })
    .sort((a, b) => b.count - a.count)
    .map((v, i) => {
      return (
        <div
          key={i}
          style={{
            display: "inline-block",
            width: v.percent,
            border: "1px solid #FFF",
            textTransform: "capitalize",
            overflow: "hidden",
            height: 48,
            color: "rgb(107, 107, 118)",
            fontSize: 14
          }}
        >
          <div
            style={{
              height: 20,
              backgroundColor: colors[i].hex,
              marginBottom: 5
            }}
          >
            &nbsp;
          </div>
          <div >
            {v.label.replace(/_/gi, " ")}
          </div>
        </div>
      );
    });

  return (
    <AtlasPanel title={title}>
      <Grid item xs={12} >
        {data}
      </Grid>
    </AtlasPanel>
  );
};

export default AtlasPercentBar;

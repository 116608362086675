import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@storybook/theming";
import React from "react";
import Helmet from "react-helmet";
import useSiteMetadata from "../../components/SiteMetadata";
import theme from "../../components/theme";
import { Grid } from "@material-ui/core";

interface IProps {
  children: any;
  config: any;
}

const Layout: React.FC<IProps> = (props) => {
  const { title, description } = useSiteMetadata();
  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" type="text/css" href="./css/sprite.css" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content="//viz.fredhutch.org/img/og-image.jpg"
        />
        <meta
          property="og:description"
          content="Translating data into discoveries"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/react-vis/dist/style.css"
        />
        <meta property="og:url" content="//viz.fredhutch.org" />
      </Helmet>
      <CssBaseline />
      <div
        style={{
          // backgroundImage: "url(/img/header1.jpg)",
          minHeight: "100vh"
        }}
      >
        {props.children}
      </div>
    </MuiThemeProvider >
  );
};

export default Layout;

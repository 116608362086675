import { Icon, IconButton, MenuItem, Select } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import React from "react";

export interface IPagination {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: Array<number>;
  onChangePage?: Function;
  onChangeRowsPerPage?: Function;
}

export const Pagination: React.FC<IPagination> = (props) => {

  const from = Math.max(0, props.page * props.rowsPerPage);
  const to = Math.min(props.count, Math.round(props.page + 1) * props.rowsPerPage);
  const indexLabel = (from + 1).toString(10) + " to " + to.toString(10) + " of " + props.count.toString(10);

  const onChangePage = (value: "FIRST" | "PREV" | "NEXT" | "LAST") => {
    if (!props.onChangePage) { return; }
    const page = (value === "FIRST") ? 0
      : (value === "LAST") ? Math.round(props.count / props.rowsPerPage)
        : (value === "PREV") ? Math.max(0, props.page - 1)
          : Math.min(props.page + 1, Math.round(props.count / props.rowsPerPage));
    props.onChangePage(page);
  };

  return (
    <div
      style={{
        textAlign: "right",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 1.66
      }}
    >
      <Select
        value={props.rowsPerPage}
        disableUnderline={true}
        style={{ fontSize: 14 }}
        onChange={(e) => { if (props.onChangeRowsPerPage) { props.onChangeRowsPerPage(e.target.value); } }}
      >
        {
          props.rowsPerPageOptions.map((opt: number) => (
            <MenuItem key={opt} value={opt}>
              {opt}
            </MenuItem>
          ))
        }
      </Select>
      <IconButton onClick={() => onChangePage("FIRST")} disabled={!props.page}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={() => onChangePage("PREV")} disabled={!props.page}>
        <KeyboardArrowLeft />
      </IconButton>
      {indexLabel}
      <IconButton onClick={() => onChangePage("NEXT")} disabled={!(to < props.count)}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={() => onChangePage("LAST")} disabled={!(to < props.count)}>
        <LastPageIcon />
      </IconButton>

    </div >
  );
};

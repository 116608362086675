import axios from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AtlasActionTypes, IRequestDataAction, IRequestDatumAction } from "./AtlasActions";
import {
  IAtlasDatumRequest,
  IAtlasState,
  IAtlasTable
} from "./AtlasModels";
import { ColorUtil } from "./utils/UtilColor";
import { UtilText } from "./utils/UtilText";

export const loadData: ActionCreator<
  ThunkAction<Promise<any>, IAtlasState, null, IRequestDataAction>
> = (requests: Array<IAtlasDatumRequest>) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: AtlasActionTypes.REQUEST_DATUM,
      data: requests
    });
    Promise.all(requests.map((v) => {
      return axios.get<any>(v.url, {
        responseType: (
          v.type === "json" ||
          v.type === "gene" ||
          v.type === "labels" ||
          v.type === "downloads") ? "json" : (v.type === "table" || v.type === "csv") ? "text" : "arraybuffer"
      });
    })).then((responses) => {
      try {
        const data = responses.map<any>((v, index) => {
          const request = requests[index];
          switch (request.type) {
            case "labels":
              v.data.forEach((label: any) => {

                // Build Cache Of Text Sizes
                label.values.forEach((datum: any) => {
                  UtilText.measureText(datum.lbl);
                });
                // Add Additional Attributes
                label.values = label.values
                  .map((datum, idx: number) => Object.assign(
                    {}, datum, {
                    valueIndex: idx,
                    interactive: false
                  }))
                  .sort((a: any, b: any) => b.pts - a.pts)
                  .map((datum, idx: number) => {
                    return Object.assign(datum, {
                      sortIndex: idx,
                      color: ColorUtil.discreteFromIndex(idx)
                    });
                  })
                  .sort((a: any, b: any) => a.valueIndex - b.valueIndex);
              });
              return {
                request, response: v.data
              };
            case "json":
            case "downloads":
            case "gene":
              return {
                request, response: v.data
              };
            case "csv":
              const csvData = v.data.split("\n").map((w: string) => w.split(","));
              if (csvData.length > 1) {
                if (csvData[csvData.length - 1].length !== csvData[csvData.length - 2].length) {
                  csvData.pop();
                }
              }
              return {
                request, response: csvData
              };
            case "table":
              const tableData: Array<Array<string>> = v.data.split("\n").map((w: string) => w.split(","));
              const tableColTypes = tableData.shift() as Array<string>;
              const tableColNames = tableData.shift() as Array<string>;
              const response = {
                cols: tableColNames.map((colName: string, i: number) => ({ name: colName, type: tableColTypes[i] })),
                data: tableData
              } as IAtlasTable;
              return {
                request, response
              };
              break;
            case "points":
            case "attributes":
              const urlFragments = v.request.responseURL.split("/");
              const fileName = urlFragments[urlFragments.length - 1].replace(".bin", "");
              const fileFragments = fileName.split("_");
              const rows = parseInt(fileFragments[fileFragments.length - 3], 10);
              const cols = parseInt(fileFragments[fileFragments.length - 1], 10);
              const type = fileFragments[fileFragments.length - 4];
              const typedData = (type === "I16") ? new Int16Array(v.data) : new Uint8Array(v.data);
              return {
                request, response: { rows, cols, type, data: typedData }
              };
          }
        });
        dispatch({
          type: AtlasActionTypes.RESPONSE_DATA,
          payload: data
        });
      } catch (err) {
        dispatch({
          type: AtlasActionTypes.REQUEST_BLOCKING_FAILURE,
          payload: err
        });
      }
    });
  };
};

export const loadDatum: ActionCreator<
  ThunkAction<Promise<any>, IAtlasState, null, IRequestDatumAction>
> = (request: IAtlasDatumRequest) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: AtlasActionTypes.REQUEST_DATUM,
      data: request
    });
    try {
      const response = await axios.get<any>(request.url);
      dispatch({
        type: AtlasActionTypes.RESPONSE_DATUM,
        payload: response.data
      });
    } catch (err) {
      dispatch({
        type: AtlasActionTypes.REQUEST_BLOCKING_FAILURE,
        payload: err
      });
    }
  };
};

import {
  Grid,
  makeStyles,
  Theme
} from "@material-ui/core";
import React, { Fragment } from "react";
import { isArray } from "util";
import { IAtlasGene } from "../AtlasModels";
import AtlasPanel from "./AtlasPanel";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "120px 0px"
  },
  searchBox: {
    width: "30%",
    margin: "10px 0px"
  },
  link: {
    "textDecoration": "none",
    "color": theme.palette.text.primary,
    "display": "block",
    "&:visited": {
      textDecoration: "none"
    },
    "&:hover": {
      backgroundColor: "#EEEEEE"
    }
  }
}));

const AtlasGo: React.FC<any> = ({ genes, gene, theme }) => {
  const classes = useStyles(theme);

  if (genes && genes.length > 0) {
    const data = genes[gene] as IAtlasGene;

    let go: Array<any> = [];
    if (data.go.BP) {
      if (!isArray(data.go.BP)) {
        data.go.BP = [data.go.BP];
      }
      go = go.concat(data.go.BP.map((v) => Object.assign(v, { src: "BP" })));
    }
    if (data.go.CC) {
      if (!isArray(data.go.CC)) {
        data.go.CC = [data.go.CC];
      }
      go = go.concat(data.go.CC.map((v) => Object.assign(v, { src: "CC" })));
    }
    if (data.go.MF) {
      if (!isArray(data.go.MF)) {
        data.go.MF = [data.go.MF];
      }
      go = go.concat(data.go.MF.map((v) => Object.assign(v, { src: "MF" })));
    }
    go = go.map((v: any, i: number) => {
      return (
        <Grid item xs={6} key={i}>
          <a key={i} href={"" + v.id} target="blank" className={classes.link}>
            {v.src} : {v.evidence} : {v.term}
          </a>
        </Grid>
      );
    });

    return (
      <AtlasPanel title={"Gene Ontology (" + go.length + " Results)"}>
        {go}
      </AtlasPanel>
    );
  } else {
    return <Fragment />;
  }
};
export default AtlasGo;
